import React, {FC, useCallback, useState} from 'react';
import {AppBar, Button, DialogContent, Stack, TextField, Toolbar, Typography} from "@mui/material";
import {IGravesDuplicates} from "../../../models/_admin/IGravesDuplicates";
import GraveDuplicateAccordion from "./GraveDuplicateAccordion";

interface Props {
  onClose: () => void;
}

const ForceGravesDuplicates: FC<Props> = ({onClose}) => {
    const [duplicate, setDuplicate] = useState<Partial<IGravesDuplicates>>({});
    const [errors, setErrors] = useState<{ id?: string, duplicateId?: string }>({});
    const [showDuplicates, setShowDuplicates] = useState(false);

    const toggleShow = () => setShowDuplicates(prev => !prev);

    const onChangeId = (value: string) => {
      setErrors(prevState => ({...prevState, id: undefined}))

      if (showDuplicates) {
        setShowDuplicates(false);
      }

      const newValue = Number(value);

      if (Number.isNaN(newValue)) {
        setErrors(prevState => ({...prevState, id: "Введите целое число"}))
        return
      }

      if (newValue <= 0) {
        setErrors(prevState => ({...prevState, id: "Введите число больше нуля"}))
        return
      }

      setDuplicate((prevState) => ({...prevState, id: Number(value)}))
    }

    const onChangeDuplicateId = (value: string) => {
      if (showDuplicates) {
        setShowDuplicates(false);
      }

      setErrors(prevState => ({...prevState, duplicateId: undefined}))

      const newValue = Number(value);

      if (Number.isNaN(newValue)) {
        setErrors(prevState => ({...prevState, duplicateId: "Введите целое число"}))
        return
      }

      if (newValue <= 0) {
        setErrors(prevState => ({...prevState, duplicateId: "Введите число больше нуля"}))
        return
      }

      if (duplicate.id === newValue) {
        setErrors(prevState => ({...prevState, duplicateId: "id не должны совпадать"}))
        return
      }

      setDuplicate((prevState) => ({...prevState, duplicateId: Number(value)}))
    }

    return (
      <>
        <AppBar>
          <Toolbar>
            <Stack direction={'row'} justifyContent={"space-between"} width={'100%'}>
              <Button
                color={'info'}
                variant={'contained'}
                onClick={onClose}
              >
                Закрыть
              </Button>

              <Typography sx={{ml: 2, mr: 4}} variant="h4">
                Принудительное совмещение
              </Typography>

              <div/>
            </Stack>
          </Toolbar>
        </AppBar>

        <DialogContent>
          <Stack marginTop={"80px"} spacing={6}>
            <Stack direction={"row"} spacing={6}>
              <TextField
                label={"Введите id оригинала"}
                variant="outlined"
                onChange={(e) => onChangeId(e.target.value)}
                error={!!errors.id}
                helperText={errors.id}
              />

              <TextField
                label={"Введите id дубликата"}
                variant="outlined"
                onChange={(e) => onChangeDuplicateId(e.target.value)}
                error={!!errors.duplicateId}
                helperText={errors.duplicateId}
              />

              <Button variant={"contained"} onClick={toggleShow}>
                Загрузить данные
              </Button>
            </Stack>

            {showDuplicates && duplicate?.id && duplicate?.duplicateId && (
              <GraveDuplicateAccordion
                key={`${duplicate.id}+${duplicate.duplicateId}`}
                expanded={true}
                duplicate={{duplicateId: duplicate.duplicateId!, id: duplicate.id!}}
              />
            )}
          </Stack>
        </DialogContent>
      </>
    );
  }
;

export default ForceGravesDuplicates;