import React, {useEffect, useState} from 'react';
import {toast} from "react-toastify";
import {FIX_DATES_URL, WRITE_USER_HISTORY_URL} from "../../api/urls";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select, Stack
} from "@mui/material";
import styles from "../../pages/_admin/features/import/schemas/importShemas.module.scss";
import {LoadingButton} from "@mui/lab";
import useEditUserPermission
  from "../../pages/_admin/users/userActions/actions/editUserPermission/useEditUserPermission";
import {IProject} from "../../models/IProject";
import CustomCardHeader from "../UI/MyCardHeader/CustomCardHeader";
import {algorithmsTitles} from "../../utils/utils";
import SelectProject from "../SelectProject/SelectProject";
import {useCustomMutation} from "../../api/useCustomMutation";

const FixDates = () => {
  const [project, setProject] = useState<IProject | undefined>();
  const mutation = useCustomMutation()

  const onProjectClick = (project: any) => {
    setProject(project);
  }

  const onSubmit = async () => {
    try {
      if (!project) {
        toast.error("Проект не выбран")
        return
      }

      let changesDescription = {
        action: 'algorithm',
        objectId: project?.id,
        data: [{algorithmName: 'FixDates',}]
      } as any

      await mutation.mutateAsync({
        method: "post",
        data: {
          schema: project.schema,
          changesDescription,
          actionObject: 'project'
        },
        url: WRITE_USER_HISTORY_URL
      })

      const {data} = await mutation.mutateAsync({
        method: 'patch',
        url: FIX_DATES_URL,
        data: {schema: project.schema}
      })

      toast.success(data.message)
    } catch (e: any) {
      toast.error(
        <div>
          <p>{e.response?.data?.error}</p>
          <p>{e.response?.data?.message}</p>
        </div>
      )
    }
  }

  const onInfoClick = () => {
    toast(
      <>
        Корректировка дат могил.
      </>
    )
  }

  return (
    <Card elevation={6} className={styles.card}>
      <CustomCardHeader title={algorithmsTitles.FixDates} onInfoClick={onInfoClick}/>

      <CardContent>
        {project &&
          <div>
            <h3>{project.name}</h3>
          </div>
        }
      </CardContent>

      <CardActions>
        <Stack direction={'row'} spacing={3}>
          <SelectProject onProjectClick={onProjectClick}/>

          <LoadingButton
            loading={mutation.isLoading}
            onClick={onSubmit}
            variant={'contained'}
            disabled={!project}
          >
            Начать проверку
          </LoadingButton>
        </Stack>
      </CardActions>
    </Card>
  );
};

export default FixDates;