import React, { useEffect, useState } from 'react';
import styles from "./findGravesDuplicates.module.scss";
import { Button, Card, CardContent, Checkbox, Dialog, Slider, Stack, Typography } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { toast } from "react-toastify";
import { useRecoilState } from "recoil";
import ShowGravesDuplicates from "./findGravesDuplicatesComponents/ShowGravesDuplicates";
import { GravesDuplicatesAtom } from "../../store/gravesDuplicatesStore";
import { useCustomMutation } from "../../api/useCustomMutation";
import {
  GET_AND_SYNC_GRAVES_DUPLICATES_URL,
  GET_GRAVES_COUNT_URL,
  GET_GRAVES_DUPLICATES_URL,
  WRITE_USER_HISTORY_URL
} from "../../api/urls";
import { ls_setProjectInfo } from "../../helpers/localStorage";
import SelectProject from "../SelectProject/SelectProject";
import CustomCardHeader from "../UI/MyCardHeader/CustomCardHeader";
import { algorithmsTitles } from "../../utils/utils";
import ForceGravesDuplicates from "./findGravesDuplicatesComponents/ForceGravesDuplicates";

const FindGravesDuplicates = () => {
  const [project, setProject] = useState<any>()
  const [duplicates, setDuplicates] = useRecoilState(GravesDuplicatesAtom)
  const mutation = useCustomMutation()
  const [open, setOpen] = useState<"results" | "force" | undefined>(undefined);
  const [sync, setSync] = useState(false);
  const [range, setRange] = useState<{ maxCount: number, values: number[] }>({ maxCount: 100, values: [0, 100] });

  const marks = [
    {
      value: 0,
      label: 0,
    },
    {
      value: Math.floor(range.maxCount / 2),
      label: Math.floor(range.maxCount / 2),
    },
    {
      value: range.maxCount,
      label: range.maxCount,
    },
  ];

  const handleChange = (event: Event, newValue: number | number[]) => {
    const targetValue = newValue as number[];
    setRange((prev) => ({ ...prev, values: targetValue.sort((A, B) => A > B ? 1 : -1) }));
  };

  const onOpenResults = () => setOpen("results")
  const onOpenForce = () => setOpen("force")
  const onClose = () => setOpen(undefined)

  const onSubmit = async () => {
    let changesDescription = {
      action: 'algorithm',
      objectId: project.id,
      data: [{ algorithmName: 'FindGravesDuplicates' }]
    } as any

    await mutation.mutateAsync({
      method: "post",
      data: {
        schema: project.schema,
        changesDescription,
        actionObject: 'project'
      },
      url: WRITE_USER_HISTORY_URL
    })

    setDuplicates([])

    try {
      const { data } = await mutation.mutateAsync({
        method: 'post',
        url:
          sync ?
            GET_AND_SYNC_GRAVES_DUPLICATES_URL + `?schema=${project.schema}` :
            GET_GRAVES_DUPLICATES_URL + `?schema=${project.schema}`,
        data: {
          range: range.values
        }
      })

      setDuplicates(data.duplicates || [])

      toast.success(data.message, { autoClose: 30000 })
    } catch (e: any) {
      toast.error(
        <div>
          <p>{e.response?.data.message}</p>
          <p>{e.response?.data.error}</p>
        </div>
      )
    }
  }

  const onProjectClick = (project: any) => {
    ls_setProjectInfo(project)
    setProject(project)
  }

  useEffect(() => {
    if (project) {
      mutation.mutateAsync({
        method: 'get',
        url: GET_GRAVES_COUNT_URL + `?schema=${project.schema}`
      })
        .then(({ data }) => {
          setRange({ values: [0, Number(data.count)], maxCount: Number(data.count) })
        })
        .catch((e: any) => {
          toast.error(
            <div>
              <p>{e.response?.data.message}</p>
              <p>{e.response?.data.error}</p>
            </div>
          )
        })
    }
  }, [project])

  const onInfoClick = () => {
    toast(
      <>
        <p> Без синхронизации - находит могилы у которых полностью совпадают или очень схожи ФИО и даты</p>
        <p>С синхронизацией:</p>
        <p>1. Находит дубликаты</p>
        <p>2. Синхронизируем их по следующим правилам: </p>
        <p>Оригиналом считаются могила у которой есть фото, в том время как у УДАЛЯЕМОГО дубликата нет фото. </p>
        <p>В таком случае дубликат удаляется, его данные и данные его ограды добавляются в оригинал</p>
        <p>Совмещение и удаление дубликата происходит вне зависимости от того в одной ограде дубликаты, или в разных. КЛЮЧЕВОЕ - наличие или отсутствие фотографии.</p>
        <p> Если у обоих дубликатов есть фотография, то совмещение и удаление не происходит.</p>
      </>
    )
  }

  return (
    <div className={styles.container}>
      <Card elevation={6} className={styles.card_form_duplicate_form}>
        <CustomCardHeader title={algorithmsTitles.FindGravesDuplicates} onInfoClick={onInfoClick}/>

        <CardContent className={styles.form__wrapper}>
          <SelectProject onProjectClick={onProjectClick}/>

          <h2>{project ? project.name : 'Выберите проект'}</h2>

          <div>
            <Checkbox
              name={'Синхронизировать дубликаты'}
              value={sync}
              onChange={() => setSync(!sync)}
            />
            Синхронизировать дубликаты
          </div>

          <Stack width={'100%'} alignItems={"center"}>
            <Typography variant={'h6'}>Выберите интервал</Typography>
            <Slider
              marks={marks}
              max={range.maxCount}
              step={200}
              sx={{ width: '80%' }}
              getAriaLabel={() => 'Temperature range'}
              value={range.values}
              onChange={handleChange}
              valueLabelDisplay="auto"
            />
          </Stack>

          <Stack direction={'row'} spacing={2}>
            <Button
              onClick={onOpenForce}
            >
              Принудительное совмещение
            </Button>

            <Button
              disabled={!duplicates.length}
              onClick={onOpenResults}
            >
              Посмотреть результаты
            </Button>

            <LoadingButton
              onClick={onSubmit}
              loading={mutation.isLoading}
            >
              Начать поиск
            </LoadingButton>
          </Stack>
        </CardContent>
      </Card>

      <Dialog open={open === "results"} fullScreen sx={{ zIndex: 2 }}>
        <ShowGravesDuplicates onClose={onClose}/>
      </Dialog>

      <Dialog open={open === "force"} fullScreen sx={{ zIndex: 2 }}>
        <ForceGravesDuplicates onClose={onClose}/>
      </Dialog>
    </div>
  );
};

export default FindGravesDuplicates;