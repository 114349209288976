import React, {FC, useState} from 'react';
import {Accordion, AccordionDetails, AccordionSummary, Stack} from "@mui/material";
import {useQuery} from "react-query";
import {Link} from "react-router-dom";
import styles from '../findGravesDuplicates.module.scss';
import {Confirm} from "react-st-modal";
import {toast} from "react-toastify";
import GraveDuplicateDataRows from "./GraveDuplicateDataRows/GraveDuplicateDataRows";
import {IGravesDuplicates} from "../../../models/_admin/IGravesDuplicates";
import {ls_getProjectInfo} from "../../../helpers/localStorage";
import {useCustomMutation} from "../../../api/useCustomMutation";
import {getGraveById} from "../../../api/project/projectApi";
import Loader from "../../UI/Loader/Loader";
import {confirmTextHelper} from "../../../helpers/confirmTextHelper";
import {
  DELETE_GRAVE_URL,
  DELETE_PLACE_URL,
  UPDATE_GRAVE_URL, UPDATE_PLACE_URL,
  WRITE_USER_HISTORY_URL
} from "../../../api/urls";
import {IGrave} from "../../../models/IGrave";
import {validateDatesInObject} from "../../../helpers/validateDatesInObject";
import {IPlace} from "../../../models/IPlace";
import Icon from "../../UI/Icons/Icon";
import {GRAVE_PAGE} from "../../../router/routeConsts";
import CurrentPhoto from "../../../pages/project/decode/content/currentPhoto/CurrentPhoto";

interface Props {
  duplicate: IGravesDuplicates
  expanded: boolean
  onChange?: () => void
}

const GraveDuplicateAccordion: FC<Props> = ({duplicate, onChange, expanded}) => {
  const project = ls_getProjectInfo()
  const mutation = useCustomMutation()
  const [openEdit, setOpenEdit] = useState<{ grave: boolean, place: boolean }>({grave: false, place: false});

  const grave = useQuery([`grave-${duplicate.id}`, duplicate.id], () => getGraveById(Number(duplicate.id)))
  const duplicateGrave = useQuery([`grave-${duplicate.duplicateId}`, duplicate.duplicateId], () => getGraveById(Number(duplicate.duplicateId)))

  if (grave.isLoading || duplicateGrave.isLoading) {
    return <Loader/>
  }

  const onOpenClose = (entity: 'place' | 'grave') => setOpenEdit((prev) => ({...prev, [entity]: !openEdit[entity]}))

  const onDeletePlace = async (id: number | undefined, placeNumber?: number | null) => {
    try {
      if (!id) {
        toast.error('Ограда уже удалена');
        return
      }

      const {body, title, cancel, confirm} = confirmTextHelper('ограды', 'ограду')
      const result = await Confirm(body, title, confirm, cancel)

      if (!result) return

      await mutation.mutateAsync({
        method: 'delete',
        url: DELETE_PLACE_URL + id + `?schema=${project.schema}`
      })

      const changesDescription = {
        objectId: id,
        action: 'delete',
      } as any

      if (placeNumber) {
        changesDescription.objectNumber = placeNumber
      }

      await mutation.mutateAsync({
        method: "post",
        data: {
          schema: project.schema,
          changesDescription,
          actionObject: 'place'
        },
        url: WRITE_USER_HISTORY_URL
      })
    } catch (e: any) {
      toast.error(
        <p>
          {e.response?.data.message}
          {e.response?.data.error}
        </p>
      )
    }
  }

  const onDeleteGrave = async (id: number, monumentNumber?: number | null) => {
    try {
      const {body, title, cancel, confirm} = confirmTextHelper('могилы', 'могилу')
      const result = await Confirm(body, title, confirm, cancel)

      if (!result) return

      const {data} = await mutation.mutateAsync({
        method: 'delete',
        url: DELETE_GRAVE_URL + id + `?schema=${project.schema}`,
      })

      const changesDescription = {
        objectId: id,
        action: 'delete',
      } as any

      if (monumentNumber) {
        changesDescription.objectNumber = monumentNumber
      }

      await mutation.mutateAsync({
        method: "post",
        data: {
          schema: project.schema,
          changesDescription,
          actionObject: 'grave'
        },
        url: WRITE_USER_HISTORY_URL
      })

      toast.success(data.message)
    } catch (e: any) {
      toast.error(
        <p>
          {e.response?.data.message}
          {e.response?.data.error}
        </p>
      )
    }
  }

  const onSyncGraves = async (firstGrave: IGrave, secondGrave: IGrave) => {
    const updateDto = {} as any
    const s = {...firstGrave} as any

    const numberKeys = ['age', 'monumentHeight', 'monumentDepth', 'monumentWidth', 'idMonumentRgis', 'monumentNumber']
    const dateKeys = ['dob', 'dod', 'dof', 'zagsDocumentDate', 'monumentInstallationDate', 'certificateDate', 'deathRecordDate', 'docr', 'doph']
    const skipKey = ['id', 'photos', 'uuid', 'idPlace', 'place']
    const forceChangeKey = ['monumentNumber']

    validateDatesInObject(secondGrave, dateKeys)
    validateDatesInObject(s, dateKeys)

    let changesDescription = {
      action: 'edit',
      objectId: secondGrave.id,
      data: []
    } as any

    for (const [key, value] of Object.entries(secondGrave)) {
      if (skipKey.includes(key)) {
        continue;
      }

      if (forceChangeKey.includes(key) && s[key]) {
        updateDto[key] = s[key]

        if (numberKeys.includes(key) && s[key]) {
          updateDto[key] = Number(s[key])
        }

        changesDescription.data.push({
          fieldName: key,
          oldData: value,
          newData: updateDto[key]
        })

        continue
      }

      // console.log(key, value, s[key], !value && s[key])
      if (!value && s[key]) {
        updateDto[key] = s[key]

        if (numberKeys.includes(key) && s[key]) {
          updateDto[key] = Number(s[key])
        }

        changesDescription.data.push({
          fieldName: key,
          oldData: value,
          newData: updateDto[key]
        })
      }
    }

    updateDto.schema = project.schema

    if (updateDto.idMonumentRgis) {
      try {
        await mutation.mutateAsync({
          method: 'patch',
          data: {
            schema: project.schema,
            idMonumentRgis: `1000${firstGrave.idMonumentRgis}`
          },
          url: UPDATE_GRAVE_URL + firstGrave.id
        })

        await mutation.mutateAsync({
          method: "post",
          data: {
            schema: project.schema,
            changesDescription: {
              action: 'edit',
              objectId: firstGrave.id,
              data: [{
                fieldName: 'idMonumentRgis',
                oldData: firstGrave.idMonumentRgis,
                newData: `1000${firstGrave.idMonumentRgis}`
              }]
            },
            actionObject: 'grave'
          },
          url: WRITE_USER_HISTORY_URL
        })
      } catch (e) {

      }
    }

    delete updateDto.id
    delete updateDto.idPlace

    try {
      const {data: editData} = await mutation.mutateAsync({
        method: 'patch',
        data: updateDto,
        url: UPDATE_GRAVE_URL + secondGrave.id
      })

      await mutation.mutateAsync({
        method: "post",
        data: {
          schema: project.schema,
          changesDescription,
          actionObject: 'grave'
        },
        url: WRITE_USER_HISTORY_URL
      })

      await grave.refetch()
      await duplicateGrave.refetch()

      toast.success(editData.message)

    } catch (e: any) {
      console.log(e)

      toast.error(
        <div>
          <p>{e.response?.data.message}</p>
          <p>{e.response?.data.error}</p>
        </div>
      )
    }
  }

  const onSyncPlaces = async (firstPlace: IPlace | undefined, secondPlace: IPlace | undefined) => {
    if (!firstPlace || !secondPlace) {
      toast.error('Одна или обе ограды удалены')
      return
    }

    const updateDto = {} as any
    const s = {...firstPlace} as any

    const numberKeys = ['idPlaceRgis', 'sectionNumber', 'length', 'width', 'area', 'responsiblePassportSeries', 'responsiblePassportNumber', 'placeNumber']
    const forceChangeKey = ['placeNumber']

    let changesDescription = {
      action: 'edit',
      objectId: secondPlace.id,
      data: []
    } as any

    for (const [key, value] of Object.entries(secondPlace)) {
      if (forceChangeKey.includes(key) && s[key]) {
        updateDto[key] = s[key]

        if (numberKeys.includes(key) && s[key]) {
          updateDto[key] = Number(s[key])
        }

        changesDescription.data.push({
          fieldName: key,
          oldData: value,
          newData: updateDto[key]
        })

        continue
      }

      if (!value && s[key]) {
        updateDto[key] = s[key]

        if (numberKeys.includes(key)) {
          updateDto[key] = Number(s[key])
        }

        changesDescription.data.push({
          fieldName: key,
          oldData: value,
          newData: updateDto[key]
        })
      }
    }

    updateDto.schema = project.schema

    if (updateDto.idPlaceRgis) {
      try {
        await mutation.mutateAsync({
          method: 'patch',
          data: {
            schema: project.schema,
            idPlaceRgis: null
          },
          url: UPDATE_PLACE_URL + firstPlace.id
        })

        await mutation.mutateAsync({
          method: "post",
          data: {
            schema: project.schema,
            changesDescription: {
              action: 'edit',
              objectId: firstPlace.id,
              data: [{
                fieldName: 'idPlaceRgis',
                oldData: firstPlace.idPlaceRgis,
                newData: null
              }]
            },
            actionObject: 'place'
          },
          url: WRITE_USER_HISTORY_URL
        })
      } catch (e) {

      }
    }

    try {
      const {data: editData} = await mutation.mutateAsync({
        method: 'patch',
        data: updateDto,
        url: UPDATE_PLACE_URL + secondPlace.id
      })

      await mutation.mutateAsync({
        method: "post",
        data: {
          schema: project.schema,
          changesDescription,
          actionObject: 'place'
        },
        url: WRITE_USER_HISTORY_URL
      })

      await grave.refetch()
      await duplicateGrave.refetch()

      toast.success(editData.message)

    } catch (e: any) {
      console.log(e)

      toast.error(
        <div>
          <p>{e.response?.data.message}</p>
          <p>{e.response?.data.error}</p>
        </div>
      )
    }
  }

  return (
    <Accordion expanded={expanded} onChange={onChange}>
      <AccordionSummary expandIcon={<Icon icon={"expandMore"}/>}>
        <Stack direction={'row'} spacing={2}>
          <Link to={GRAVE_PAGE + duplicate.id}>
            ID могилы: {duplicate.id}
          </Link>

          {!grave.data?.data.grave || !duplicateGrave.data?.data.grave ? <h1>Одна из могил удалена</h1> :
            <div>
              {grave.data?.data.grave.firstname} {' '}
              {grave.data?.data.grave.lastname} {' '}
              {grave.data?.data.grave.patronymic} {' '}
            </div>
          }

          <Link to={GRAVE_PAGE + duplicate.duplicateId}>
            ID найденного дубликата: {duplicate.duplicateId}
          </Link>
        </Stack>
      </AccordionSummary>

      <AccordionDetails>
        {!grave.data?.data.grave || !duplicateGrave.data?.data.grave ? <h1>Одна из могил удалена</h1> :
          <Stack spacing={3}>
            <Stack direction={'row'} height={'75vh'}>
              <div>
                <Link to={GRAVE_PAGE + grave.data?.data.grave.id}>
                  ID могилы: {grave.data?.data.grave.id}
                </Link>

                <CurrentPhoto projectInfo={project} currentGrave={grave.data?.data.grave}/>
              </div>

              <div>
                <Link to={GRAVE_PAGE + duplicateGrave.data?.data.grave.id}>
                  ID найденного дубликата: {duplicateGrave.data?.data.grave.id}
                </Link>

                <CurrentPhoto projectInfo={project} currentGrave={duplicateGrave.data?.data.grave}/>
              </div>
            </Stack>

            <div className={styles.find_duplicates__accordion__table__wrapper}>
              <div className={styles.find_duplicates__accordion__table}>
                <GraveDuplicateDataRows
                  onDeletePlace={onDeletePlace}
                  duplicate={duplicateGrave.data?.data.grave}
                  grave={grave.data?.data.grave}
                  openEdit={openEdit}
                  onOpenClose={onOpenClose}
                  onDeleteGrave={onDeleteGrave}
                  onSyncGraves={onSyncGraves}
                  onSyncPlaces={onSyncPlaces}
                />
              </div>
            </div>
          </Stack>
        }
      </AccordionDetails>
    </Accordion>
  );
};

export default GraveDuplicateAccordion;